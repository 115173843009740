import React from "react";
import Content from "../components/Common/Content";
import Layout from "../components/Common/Layout";
import Main from "../components/Common/Main";
import Paragraph from "../components/Common/Paragrpah";
import Section from "../components/Common/Section";
import Subtitle from "../components/Common/Subtitle";
import Title from "../components/Common/Title";
import Spacing from "../constants/Spacing";

const FAQ = () => {
    return (
        <Layout title="FAQ">
            <Main>
                <Section marginBottom={Spacing.SPACING_7}>
                    <Content>
                        <Title
                            marginTop={Spacing.SPACING_3}
                            marginBottom={Spacing.SPACING_3}
                        >
                            Frequently Asked Questions
                        </Title>
                        <Subtitle marginBottom={Spacing.SPACING_2}>
                            For how long can I use the package after the
                            purchase?
                        </Subtitle>
                        <Paragraph marginBottom={Spacing.SPACING_3}>
                            After the purchase, you will be able to access
                            everything for 365 days. That should be more than
                            enough to sharpen your command line skills!
                        </Paragraph>
                        <Subtitle marginBottom={Spacing.SPACING_2}>
                            What if I don't like it?
                        </Subtitle>
                        <Paragraph marginBottom={Spacing.SPACING_3}>
                            If you're not happy with the program, for any
                            reason, you can reach out to us by email in the
                            first 7 days, and we'll refund your purchase, no
                            questions asked.
                            <br />
                            <br />
                            We will just ask why you weren't happy since that
                            feedback will help us make the program better! But
                            you don't have to answer it. The refund is
                            unconditional.
                        </Paragraph>
                        <Subtitle marginBottom={Spacing.SPACING_2}>
                            What if I get stuck? Can I ask questions?
                        </Subtitle>
                        <Paragraph marginBottom={Spacing.SPACING_3}>
                            Absolutely! <br />
                            <br />
                            We use Slack as our community platform. There is a
                            #help channel specifically for questions related to
                            the materials (or command line and Linux in
                            general). There are also specific channels for each
                            module.
                        </Paragraph>
                        <Subtitle marginBottom={Spacing.SPACING_2}>
                            Do you offer a certificate of completion?
                        </Subtitle>
                        <Paragraph marginBottom={Spacing.SPACING_3}>
                            Yes! Anyone that completes all the modules will be
                            able to download a pdf certificate with their name
                            on it, showing that they've completed the LearnCMD
                            educational program.
                            <br />
                            <br />
                            This can be useful to claim reimbursement from your
                            employer, to share on social media, or just feel
                            great about your accomplishment!
                        </Paragraph>
                        <Subtitle marginBottom={Spacing.SPACING_2}>
                            What do I need for this program?
                        </Subtitle>
                        <Paragraph marginBottom={Spacing.SPACING_3}>
                            Just a browser and some free time. There is no
                            struggle with downloading and installing any
                            software package to your local machine. No Linux or
                            command line experience is required.
                        </Paragraph>
                        <Subtitle marginBottom={Spacing.SPACING_2}>
                            What is included?
                        </Subtitle>
                        <Paragraph marginBottom={Spacing.SPACING_3}>
                            There are 8 Developer modules and 10 DevOps modules
                            with over 100 lessons. Furthermore, there is a huge
                            number of cheat-sheets, diagrams, command
                            walkthroughts and extra materials.
                        </Paragraph>
                        <Subtitle marginBottom={Spacing.SPACING_2}>
                            What about a receipt, so I can be reimbursed?
                        </Subtitle>
                        <Paragraph marginBottom={Spacing.SPACING_3}>
                            Right after purchasing, you'll be emailed a
                            receipt/invoice. You'll be able to add whatever name
                            and address are necessary to facilitate
                            reimbursement.
                            <br />
                            <br />
                            You can also add your VAT number, to have any VAT
                            charges reimbursed.
                        </Paragraph>
                        <Subtitle marginBottom={Spacing.SPACING_2}>
                            Which Linux distribution and shell is this based on?
                        </Subtitle>
                        <Paragraph marginBottom={Spacing.SPACING_3}>
                            The learning environment is based on Ubuntu Linux
                            and Bash shell. However, almost all materials are
                            applicable for most Linux distributions and most of
                            the common shells. It is pretty relevant for Mac
                            users too.
                        </Paragraph>
                        <Subtitle marginBottom={Spacing.SPACING_2}>
                            I have a question not listed here!
                        </Subtitle>
                        <Paragraph marginBottom={Spacing.SPACING_3}>
                            We can be reached at{" "}
                            <strong>team@learncmd.io</strong> Feel free to ask
                            anything.
                        </Paragraph>
                    </Content>
                </Section>
            </Main>
        </Layout>
    );
};

export default FAQ;
